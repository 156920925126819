import getImageBlobUrl from 'services/getImageBlobUrl';

import { AI_PHOTOSHOOTING__BASE_PATH, AVATAR_PATH } from 'utils/configs';

const processAvatarImages = async dataArray => {
  return await Promise.all(
    dataArray.map(async item => {
      const avatarBlob = await getImageBlobUrl(
        `${AI_PHOTOSHOOTING__BASE_PATH}${AVATAR_PATH}`,
        item.image,
        ''
      );
      return {
        ...item,
        image: avatarBlob,
      };
    })
  );
};

export default processAvatarImages;
