import { createSlice } from '@reduxjs/toolkit';
import {
  createBGTaskFromGallery,
  createBGTaskFromUploaded,
  getBgTaskList,
  goToTheBgTask,
  removeBgTask,
  sendBgImageFeedback,
} from './operations';

import { TESTTASK_ID, TEST_IMAGE_ID } from 'utils/configs';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result_transparent.png';

const defaultTask = {
  id: TESTTASK_ID,
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      input_image_small: defaultInputImage,
      input_image_large: defaultInputImage,
      results: [
        {
          id: 'testimage',
          background: [246, 246, 246, 255],
          result_image: defaultInputImage,
          result_image_basic: defaultInputImage,
          result_image_large: defaultInputImage,
        },
        {
          id: 'testimage_transparent',
          background: [0, 0, 0, 0],
          result_image: defaultResultImage,
          result_image_basic: defaultResultImage,
          result_image_large: defaultResultImage,
        },
      ],
    },
  ],
  status: 'test',
};

let bgRemoveInitialState = {
  bgTaskList: [],
  bgTaskDetails: null,
  bgTaskStatus: null,
  bgIsLoading: false,
  bgResponseErrorMessage: null,
};

const handlePending = state => {
  state.bgResponseErrorMessage = null;
  state.bgIsLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.bgResponseErrorMessage = payload;
  state.bgIsLoading = false;
};

export const bgRemoveSlice = createSlice({
  name: 'bgremove',
  initialState: bgRemoveInitialState,

  reducers: {
    bgResetTaskDetails(state) {
      state.bgTaskDetails = null;
      state.bgTaskStatus = null;
    },

    bgResetTaskList(state) {
      state.bgTaskList = [];
    },
  },

  extraReducers: builder => {
    builder
      .addCase(createBGTaskFromGallery.pending, handlePending)
      .addCase(createBGTaskFromGallery.rejected, handleRejected)
      .addCase(createBGTaskFromGallery.fulfilled, (state, { payload }) => {
        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      })
      .addCase(createBGTaskFromUploaded.pending, handlePending)
      .addCase(createBGTaskFromUploaded.rejected, handleRejected)
      .addCase(createBGTaskFromUploaded.fulfilled, (state, { payload }) => {
        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      })
      .addCase(getBgTaskList.pending, handlePending)
      .addCase(getBgTaskList.rejected, handleRejected)
      .addCase(getBgTaskList.fulfilled, (state, { payload }) => {
        if (payload.length > 0) {
          state.bgTaskList = payload;
        } else {
          state.bgTaskList = [defaultTask];
        }

        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      })
      .addCase(goToTheBgTask.pending, handlePending)
      .addCase(goToTheBgTask.rejected, handleRejected)
      .addCase(goToTheBgTask.fulfilled, (state, { payload }) => {
        state.bgTaskDetails = payload;
        state.bgTaskStatus = payload.status;
        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      })
      .addCase(removeBgTask.pending, handlePending)
      .addCase(removeBgTask.rejected, handleRejected)
      .addCase(removeBgTask.fulfilled, (state, { payload }) => {
        state.bgTaskList = state.bgTaskList.filter(task => task.id !== payload);
        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      })
      .addCase(sendBgImageFeedback.pending, handlePending)
      .addCase(sendBgImageFeedback.rejected, handleRejected)
      .addCase(sendBgImageFeedback.fulfilled, (state, { payload }) => {
        const imageArrCopy = [...state.bgTaskDetails.images];
        const updatedImage = state.bgTaskDetails.images.find(
          el => el.id === payload.imageId
        );
        if (updatedImage) updatedImage.feedback = payload.value;

        state.bgTaskDetails.images = imageArrCopy;
        state.bgIsLoading = false;
        state.bgResponseErrorMessage = null;
      });
  },
});

export const { bgResetTaskDetails, bgResetTaskList } = bgRemoveSlice.actions;
export const bgRemoveReducer = bgRemoveSlice.reducer;
