import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AI_GENERATE_MODELS__BASE_PATH,
  AI_PHOTOSHOOTING__BASE_PATH,
  AVATAR_PATH,
  TESTTASK_ID,
  TEST_IMAGE_ID,
} from 'utils/configs';

import handleError from 'services/handleError';
import deleteTask from 'services/deleteTask';
import processAvatarImages from 'services/processAvatarImages';
import getImageBlobUrl from 'services/getImageBlobUrl';

import defaultInputImage from 'media/images/test_ai_model_input.jpg';
import defaultResultImage from 'media/images/test_ai_model_result.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  avatar: { image: 'girl1.jpeg' },
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      input_image_small: defaultInputImage,
      input_image_large: defaultInputImage,
      result_image: defaultResultImage,
      result_image_basic: defaultResultImage,
      result_image_large: defaultResultImage,
    },
  ],
  status: 'test',
};

export const getAiGenerateModelsAvatars = createAsyncThunk(
  'aigeneratemodels/getAvatars',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${AI_GENERATE_MODELS__BASE_PATH}/avatar/query`
      );

      const processedData = await processAvatarImages(data); // Обробка всіх зображень

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/create',

  async (_, thunkAPI) => {
    /**Will be changed with the ready backend */

    return 'id';
  }
);

export const getAiGenerateModelsTaskList = createAsyncThunk(
  'aigeneratemodels/gallery',

  async (_, thunkAPI) => {
    let processedData = [];

    /**Will be changed with the ready backend */

    const avatarBlob = await getImageBlobUrl(
      `${AI_PHOTOSHOOTING__BASE_PATH}${AVATAR_PATH}`,
      defaultTask.avatar.image,
      ''
    );
    processedData = [{ ...defaultTask, avatar: { image: avatarBlob } }];
    // Обробка всіх зображень

    return processedData;
  }
);

export const goToTheAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/goToTheTask',

  async (taskId, thunkAPI) => {
    let processedData = [];
    try {
      if (taskId === TESTTASK_ID) {
        const avatarBlob = await getImageBlobUrl(
          `${AI_PHOTOSHOOTING__BASE_PATH}${AVATAR_PATH}`,
          defaultTask.avatar.image,
          ''
        );
        processedData = [{ ...defaultTask, avatar: { image: avatarBlob } }]; // Обробка всіх зображень
      } else {
        // const data = await goToTheTask(
        //   AI_GENERATE_MODELS__BASE_PATH,
        //   taskId,
        //   thunkAPI
        // );
        // processedData = await processImages([data]); // Обробка всіх зображень
      }

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/removeTask',

  async (taskId, thunkAPI) => {
    const data = deleteTask(AI_GENERATE_MODELS__BASE_PATH, taskId, thunkAPI);

    return data;
  }
);
