import icon from 'media/images/svgSprite.svg';
import {
  BtnByContent,
  IconBtnByContent,
  TransparentBtn,
  IconBtn,
  AddImgButton,
  CreateBtn,
  DeleteBtn,
  DeleteIcon,
  DeleteSmallBtn,
  ExtraBtnIcon,
  ExitButton,
  ExtraButton,
  GettingInfoBtn,
  ModalBtnAccent,
  ModalBtnLight,
  LightBtn,
  NextBtn,
  PrevBtn,
  SliderIcon,
  SubmitButton,
  ControlBtn,
  ModalBtnGratitude,
  GratitudeIcon,
  PriceBtn,
  BuyBtn,
  MainAccentBtn,
  ApprovalBtn,
  EditBtn,
} from 'components/common/Buttons/Buttons.styled';

export const ButtonByContent = ({
  gbColor,
  textColor,
  children,
  handleClick,
}) => {
  return (
    <BtnByContent
      $gbColor={gbColor}
      $textColor={textColor}
      type="button"
      onClick={handleClick}
    >
      {children}
    </BtnByContent>
  );
};

export const IconButtonByContent = ({ children, handleClick, isDisabled }) => {
  return (
    <IconBtnByContent type="button" onClick={handleClick} disabled={isDisabled}>
      {children}
    </IconBtnByContent>
  );
};

export const TransparentButton = ({ children, handleClick, isDisabled }) => {
  return (
    <TransparentBtn type="button" disabled={isDisabled} onClick={handleClick}>
      {children}
    </TransparentBtn>
  );
};

export const LightButton = ({ children, handleClick }) => {
  return (
    <LightBtn type="button" onClick={handleClick}>
      {children}
    </LightBtn>
  );
};

export const ControlButton = ({
  actionType,
  handleClick,
  disableStatus,
  children,
}) => {
  return (
    <ControlBtn
      type="button"
      $actionType={actionType}
      onClick={handleClick}
      disabled={disableStatus}
    >
      {children}
    </ControlBtn>
  );
};

export const ExtraBtn = ({ children }) => {
  return <ExtraButton type="button">{children}</ExtraButton>;
};

export const ExitBtn = ({ children, handleLogout }) => {
  return (
    <ExitButton type="button" onClick={handleLogout}>
      {children}
    </ExitButton>
  );
};

export const IconButton = ({ children, handleClick, btnColor }) => {
  return (
    <IconBtn $btnColor={btnColor} type="button" onClick={handleClick}>
      {children}
    </IconBtn>
  );
};

//-------------------Slider------------------------

export const ArrowLeft = () => {
  return (
    <PrevBtn type="button">
      <SliderIcon>
        <use href={`${icon}#icon-arrow-left`}></use>
      </SliderIcon>
    </PrevBtn>
  );
};

export const ArrowRight = () => {
  return (
    <NextBtn type="button">
      <SliderIcon>
        <use href={`${icon}#icon-arrow-right`}></use>
      </SliderIcon>
    </NextBtn>
  );
};

//-------------------Image List------------------------

export const AddImgBtn = ({ children, disableStatus, handleClick }) => {
  return (
    <AddImgButton type="button" disabled={disableStatus} onClick={handleClick}>
      {children}
    </AddImgButton>
  );
};

export const DeleteButton = ({ handleDeleteImage, id, isBig }) => {
  return (
    <DeleteBtn type="button" onClick={() => handleDeleteImage(id)}>
      <DeleteIcon $size={isBig}>
        <use href={`${icon}#icon-remove`}></use>
      </DeleteIcon>
    </DeleteBtn>
  );
};

export const DeleteSmallButton = ({ handleDeleteImage, id, isBig }) => {
  return (
    <DeleteSmallBtn type="button" onClick={e => handleDeleteImage(e, id)}>
      <DeleteIcon $size={isBig}>
        <use href={`${icon}#icon-remove`}></use>
      </DeleteIcon>
    </DeleteSmallBtn>
  );
};

//----------------------Submit-------------------------
export const SubmitBtn = ({ children, disableStatus }) => {
  return (
    <SubmitButton type="submit" disabled={disableStatus}>
      {children}
    </SubmitButton>
  );
};

//----------------------Gallery-------------------------

export const CreateButton = ({ handleClick, children }) => {
  return (
    <CreateBtn type="button" onClick={handleClick}>
      {children}
    </CreateBtn>
  );
};

export const GettingInfoButton = ({ handleClick, children }) => {
  return (
    <GettingInfoBtn type="button" onClick={handleClick}>
      {children}
    </GettingInfoBtn>
  );
};

export const ExtraButtonIcon = ({
  handleDeleteElement,
  children,
  disableStatus,
}) => {
  return (
    <ExtraBtnIcon
      type="button"
      onClick={handleDeleteElement}
      disabled={disableStatus}
    >
      {children}
    </ExtraBtnIcon>
  );
};

//----------------------Modal Button---------------------

export const ModalButtonLight = ({ children, closeModal }) => {
  return (
    <ModalBtnLight type="button" onClick={closeModal}>
      {children}
    </ModalBtnLight>
  );
};

export const ModalButtonAccent = ({ children, disableStatus, closeModal }) => {
  return (
    <ModalBtnAccent type="button" disabled={disableStatus} onClick={closeModal}>
      {children}
    </ModalBtnAccent>
  );
};

export const ModalButtonSubmit = ({ children }) => {
  return <ModalBtnAccent type="submit">{children}</ModalBtnAccent>;
};

export const ModalButtonGratitude = ({ closeModal }) => {
  return (
    <ModalBtnGratitude type="button" onClick={closeModal}>
      <GratitudeIcon>
        <use href={`${icon}#icon-check-green`}></use>
      </GratitudeIcon>
    </ModalBtnGratitude>
  );
};

//---------------------Price Page---------------------------

export const PriceMainButton = ({ handleClick, children }) => {
  return (
    <PriceBtn type="button" onClick={handleClick}>
      {children}
    </PriceBtn>
  );
};

export const PriceButton = ({
  priceType,
  handleClick,
  disableStatus,
  children,
}) => {
  return (
    <BuyBtn
      type="button"
      $free={priceType}
      onClick={handleClick}
      disabled={disableStatus}
    >
      {children}
    </BuyBtn>
  );
};

//------------------Fashion Store-------------------

export const MainAccentButton = ({ handleClick, disableStatus, children }) => {
  return (
    <MainAccentBtn type="button" onClick={handleClick} disabled={disableStatus}>
      {children}
    </MainAccentBtn>
  );
};

export const ApprovalButton = ({ handleClick, children }) => {
  return (
    <ApprovalBtn type="button" onClick={handleClick}>
      {children}
    </ApprovalBtn>
  );
};

export const EditButton = ({
  handleClick,
  actionType,
  isDisabled,
  children,
}) => {
  return (
    <EditBtn
      type="button"
      $actionType={actionType}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {children}
    </EditBtn>
  );
};
