import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AI_FASHION_STORE__BASE_PATH,
  LARGE_IMAGE_PATH,
  SMALL_IMAGE_PATH,
  TESTTASK_ID,
  TEST_IMAGE_ID,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import deleteTask from 'services/deleteTask';
import sendFeedback from 'services/sendFeedback';
import getCachedImageBlobUrl from 'services/getCachedImageBlobUrl';
import { getShopifySessionToken } from 'services/axiosConfig';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result.jpg';

async function getShopifyParams(thunkAPI) {
  let sessionToken = null;
  const state = thunkAPI.getState();
  const app = state.fashionstore.integrations.shopify.shopifyApp;

  if (app) {
    sessionToken = await getShopifySessionToken(app);
  }

  return sessionToken;
}

const defaultTask = {
  id: TESTTASK_ID,
  name: 'Example',
  images: [
    {
      id: TEST_IMAGE_ID,
      location: defaultInputImage,
      location_basic: defaultInputImage,
      location_large: defaultInputImage,
      location_small: defaultInputImage,
      source: {
        type: 'test',
      },
    },
    {
      id: `${TEST_IMAGE_ID}2`,
      location: defaultResultImage,
      location_basic: defaultResultImage,
      location_large: defaultResultImage,
      location_small: defaultResultImage,
      source: {
        type: 'test',
      },
    },
  ],
  details: {
    subject: 'Example',
    description:
      "I'm a product description. I'm a great place to add more details about your product such as sizing, material, etc.",
    info: "I'm a product info. I'm a great place to add more details about your product such as care and cleaning instructions.",
  },
};

function getBaseUrl(id) {
  return `${AI_FASHION_STORE__BASE_PATH}/${id}/image/`;
}

const processImages = async dataArray => {
  return await Promise.all(
    dataArray.map(async item => {
      const imagesWithBlobs = await Promise.all(
        item.images.map(async img => ({
          ...img,

          location_basic: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.location
          ),

          location_large: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.location,
            LARGE_IMAGE_PATH
          ),
          location_small: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.location,
            SMALL_IMAGE_PATH
          ),
        }))
      );

      return {
        ...item,
        images: imagesWithBlobs,
      };
    })
  );
};

export const createFSOrderWithTwoFileTypes = createAsyncThunk(
  'fashionStore/createWithTwoFileTypes',

  async (orderData, thunkAPI) => {
    try {
      let taskId = null;
      const formData = new FormData();

      if (orderData.ownImages.length > 0) {
        orderData.ownImages.forEach((item, index) => {
          formData.append('images', orderData.ownImages[index]);
        });

        const { data } = await axios.post(
          `${AI_FASHION_STORE__BASE_PATH}/create-from-uploaded`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        taskId = data;
      }

      if (
        orderData.galleryImages.length > 0 &&
        orderData.ownImages.length > 0
      ) {
        await axios.post(
          `${AI_FASHION_STORE__BASE_PATH}/${taskId.id}/image/add-from-gallery`,
          { images: orderData.galleryImages }
        );
      }

      if (
        orderData.galleryImages.length > 0 &&
        orderData.ownImages.length === 0
      ) {
        const { data } = await axios.post(
          `${AI_FASHION_STORE__BASE_PATH}/create-from-gallery`,
          { images: orderData.galleryImages }
        );

        taskId = data;
      }

      return taskId;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const getFSOrderList = createAsyncThunk(
  'fashionStore/getFSOrderList',

  async (_, thunkAPI) => {
    try {
      let processedData = [];
      const data = await getTaskList(AI_FASHION_STORE__BASE_PATH, thunkAPI);

      if (data.length > 0) {
        processedData = await processImages(data); // Обробка всіх зображень
      } else {
        processedData = [defaultTask];
      }

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const deleteFSOrder = createAsyncThunk(
  'fashionStore/deleteFSOrder',

  async (orderId, thunkAPI) => {
    try {
      const data = deleteTask(AI_FASHION_STORE__BASE_PATH, orderId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  'fashionStore/getOrderDetails',

  async (orderId, thunkAPI) => {
    try {
      let processedData = [];
      if (orderId === TESTTASK_ID) {
        processedData = [defaultTask];
      } else {
        const { data } = await axios.get(
          `${AI_FASHION_STORE__BASE_PATH}/${orderId}/read`
        );

        processedData = await processImages([data]); // Обробка всіх зображень
      }

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const addImageFromGallery = createAsyncThunk(
  'fashionStore/addImageFromGallery',

  async (orderData, thunkAPI) => {
    try {
      let processedData = [];

      await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/image/add-from-gallery`,
        { images: orderData.images }
      );

      const { data } = await axios.get(
        `${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/read`
      );

      processedData = await processImages([data]); // Обробка всіх зображень

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const addImageFromUploaded = createAsyncThunk(
  'fashionStore/addImageFromUploaded',

  async (orderData, thunkAPI) => {
    try {
      let processedData = [];
      const formData = new FormData();

      orderData.images.forEach(item => {
        formData.append('images', item);
      });

      await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/image/add-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const { data } = await axios.get(
        `${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/read`
      );

      processedData = await processImages([data]); // Обробка всіх зображень

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const deleteImageFS = createAsyncThunk(
  'fashionStore/deleteImageFS',

  async (imageData, thunkAPI) => {
    try {
      await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${imageData.orderId}/image/${imageData.imageId}/delete`
      );

      return imageData.imageId;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const updateFSOrderDetails = createAsyncThunk(
  'fashionStore/updateFSOrderDetails',

  async (orderData, thunkAPI) => {
    try {
      await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/details/update`,
        orderData.newMeta
      );

      return orderData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const generateFSOrderDetails = createAsyncThunk(
  'fashionStore/generateFSOrderDetails',

  async (reqParams, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${reqParams.orderId}/details/generate`,
        reqParams.meta
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const sendFSImageFeedback = createAsyncThunk(
  'fashionStore/imageFeedback',

  async (feedbackBody, thunkAPI) => {
    try {
      const { value, taskId } = feedbackBody;
      const toolPath = `${AI_FASHION_STORE__BASE_PATH}`;
      const imageId = null;

      const responce = await sendFeedback(
        value,
        toolPath,
        taskId,
        imageId,
        thunkAPI
      );

      return responce;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const publishOrderInShopify = createAsyncThunk(
  'fashionStore/publishOrderInShopify',

  async (orderId, thunkAPI) => {
    try {
      const sessionToken = await getShopifyParams(thunkAPI);

      const { data } = await axios.post(
        `${AI_FASHION_STORE__BASE_PATH}/${orderId}/publish-to-shopify`,
        null,
        {
          headers: sessionToken,
        }
      );

      return data.product_id;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
