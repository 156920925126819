import { createSlice } from '@reduxjs/toolkit';
import {
  createFSOrderWithTwoFileTypes,
  getOrderDetails,
  addImageFromGallery,
  addImageFromUploaded,
  deleteImageFS,
  getFSOrderList,
  deleteFSOrder,
  updateFSOrderDetails,
  generateFSOrderDetails,
  sendFSImageFeedback,
  publishOrderInShopify,
} from './operations';

import { TESTTASK_ID, TEST_IMAGE_ID } from 'utils/configs';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  name: 'Example',
  images: [
    {
      id: TEST_IMAGE_ID,
      location: defaultInputImage,
      location_basic: defaultInputImage,
      location_large: defaultInputImage,
      location_small: defaultInputImage,
      source: {
        type: 'test',
      },
    },
    {
      id: `${TEST_IMAGE_ID}2`,
      location: defaultResultImage,
      location_basic: defaultResultImage,
      location_large: defaultResultImage,
      location_small: defaultResultImage,
      source: {
        type: 'test',
      },
    },
  ],
  details: {
    subject: 'Example',
    description:
      "I'm a product description. I'm a great place to add more details about your product such as sizing, material, etc.",
    info: "I'm a product info. I'm a great place to add more details about your product such as care and cleaning instructions.",
  },
};

let fashionStoreInitialState = {
  fsOrderList: [],
  fsTextDataForOrder: {
    name: '',
    subject: '',
    description: '',
    info: '',
    feedback: 'not-rated',
  },
  fsOrderImages: [],
  integrations: {
    shopify: { product_id: null, shopifyApp: null, shopifyShopName: null },
  },
  fsIsLoading: false,
  fsResponseErrorMessage: null,
};

const handlePending = state => {
  state.fsResponseErrorMessage = null;
  state.fsIsLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.fsResponseErrorMessage = payload;
  state.fsIsLoading = false;
};

export const fashionStoreSlice = createSlice({
  name: 'fashionstore',
  initialState: fashionStoreInitialState,

  reducers: {
    fsSetShopifyParams(state, { payload }) {
      state.integrations.shopify = {
        ...state.integrations.shopify,
        ...payload,
      };
    },
    fsResetTaskList(state) {
      state.fsOrderList = [];
    },

    fsResetFsState(state) {
      state.fsOrderImages = [];
      state.fsTextDataForOrder = fashionStoreInitialState.fsTextDataForOrder;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(createFSOrderWithTwoFileTypes.pending, handlePending)
      .addCase(createFSOrderWithTwoFileTypes.rejected, handleRejected)
      .addCase(createFSOrderWithTwoFileTypes.fulfilled, state => {
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(getOrderDetails.pending, handlePending)
      .addCase(getOrderDetails.rejected, handleRejected)
      .addCase(getOrderDetails.fulfilled, (state, { payload }) => {
        state.fsOrderImages = payload.images;
        state.fsTextDataForOrder = {
          ...state.fsTextDataForOrder,
          ...payload.details,
          name: payload.name,
        };
        state.integrations.shopify = {
          ...state.integrations.shopify,
          ...payload.integrations.shopify,
        };
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(addImageFromGallery.pending, handlePending)
      .addCase(addImageFromGallery.rejected, handleRejected)
      .addCase(addImageFromGallery.fulfilled, (state, { payload }) => {
        state.fsOrderImages = payload.images;
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(addImageFromUploaded.pending, handlePending)
      .addCase(addImageFromUploaded.rejected, handleRejected)
      .addCase(addImageFromUploaded.fulfilled, (state, { payload }) => {
        state.fsOrderImages = payload.images;
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(deleteImageFS.pending, handlePending)
      .addCase(deleteImageFS.rejected, handleRejected)
      .addCase(deleteImageFS.fulfilled, (state, { payload }) => {
        const newArr = state.fsOrderImages.filter(item => item.id !== payload);
        state.fsOrderImages = newArr;
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(getFSOrderList.pending, handlePending)
      .addCase(getFSOrderList.rejected, handleRejected)
      .addCase(getFSOrderList.fulfilled, (state, { payload }) => {
        if (payload.length > 0) {
          state.fsOrderList = payload;
        } else {
          state.fsOrderList = [defaultTask];
        }
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(deleteFSOrder.pending, handlePending)
      .addCase(deleteFSOrder.rejected, handleRejected)
      .addCase(deleteFSOrder.fulfilled, (state, { payload }) => {
        state.fsOrderList = state.fsOrderList.filter(
          order => order.id !== payload
        );
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(updateFSOrderDetails.pending, handlePending)
      .addCase(updateFSOrderDetails.rejected, handleRejected)
      .addCase(updateFSOrderDetails.fulfilled, (state, { payload }) => {
        const keys = Object.keys(payload.newMeta);
        if (keys.length === 1 && keys[0] === 'name') {
          const updatedList = [...state.fsOrderList];
          updatedList.forEach(order => {
            if (order.id === payload.orderId) {
              order.name = payload.newMeta.name;
            }
          });
          state.fsOrderList = updatedList;
        } else {
          state.fsTextDataForOrder = {
            ...state.fsTextDataForOrder,
            ...payload.newMeta,
          };
        }
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(generateFSOrderDetails.pending, () => {})
      .addCase(generateFSOrderDetails.rejected, handleRejected)
      .addCase(generateFSOrderDetails.fulfilled, (state, { payload }) => {
        state.fsTextDataForOrder = {
          ...state.fsTextDataForOrder,
          ...payload,
          feedback: 'not-rated',
        };
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      })
      .addCase(sendFSImageFeedback.pending, state => {
        state.fsResponseErrorMessage = null;
      })
      .addCase(sendFSImageFeedback.rejected, handleRejected)
      .addCase(sendFSImageFeedback.fulfilled, (state, { payload }) => {
        state.fsTextDataForOrder.feedback = payload.value;
        state.fsResponseErrorMessage = null;
      })
      .addCase(publishOrderInShopify.pending, handlePending)
      .addCase(publishOrderInShopify.rejected, handleRejected)
      .addCase(publishOrderInShopify.fulfilled, state => {
        state.fsIsLoading = false;
        state.fsResponseErrorMessage = null;
      });
  },
});

export const { fsSetShopifyParams, fsResetTaskList, fsResetFsState } =
  fashionStoreSlice.actions;
export const fashionStoreReducer = fashionStoreSlice.reducer;
