import axios from 'axios';
import handleError from 'services/handleError';

const sendFeedback = async (value, toolPath, taskId, imageId, thunkAPI) => {
  let dynamicUrlPart = '';

  if (imageId) {
    dynamicUrlPart = `/image/${imageId}`;
  }

  try {
    await axios.post(`${toolPath}/${taskId}${dynamicUrlPart}/feedback`, {
      value,
    });

    return { imageId, value };
  } catch (error) {
    const { status, data } = error.response;
    const errorDetails = handleError(status, data);

    return thunkAPI.rejectWithValue(errorDetails);
  }
};

export default sendFeedback;
