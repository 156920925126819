import styled from 'styled-components';
import { vars } from 'utils/variables';

export const CreditsWrap = styled.section`
  max-width: 194px;
`;

export const CreditsText = styled.p`
  font-size: 16px;
  font-weight: 500;

  color: ${vars.darkBlack100};
`;

export const Progress = styled.div`
  height: 10px;
  margin-top: 8px;

  position: relative;

  background-color: ${vars.darkTransparent5};
  border-radius: 5px;

  &::before {
    content: '';
    width: ${props => (props.$used ? `calc(${props.$used} * 1%)` : '7%')};
    height: 100%;
    min-width: 1%;
    max-width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: ${vars.primaryRose};
    border-radius: 5px;
  }
`;

export const PlanDescription = styled.ul`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DescriptionItem = styled.li`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
`;

export const ItemTitle = styled.h5`
  width: 100%;
  color: ${vars.secondaryBlue};

  @media screen and (min-width: 768px) {
    width: 260px;
  }

  @media screen and (min-width: 1200px) {
    width: 350px;
  }
`;

export const PlanWrap = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media screen and (min-width: 768px) {
    gap: 24px;
  }
`;

export const ExpirationLable = styled.button`
  height: 24px;
  padding: 2px 4px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background-color: ${vars.dark5};
  color: ${vars.dark30};
  border: 1px solid ${vars.darkTransparent5};
  border-radius: 8px;

  font-size: 11px;
  line-height: 10px;

  transition: background-color ${vars.transitionTimingFunction};

  &:hover {
    background-color: ${vars.dark70};
  }

  &:hover::after {
    opacity: 1;
  }

  &:hover > svg,
  &:hover > span {
    opacity: 0;
  }

  & > span {
    transition: opacity ${vars.transitionTimingFunction};
  }

  &::after {
    content: 'Save subscription';
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${vars.light100};
    opacity: 0;
    transition: opacity ${vars.transitionTimingFunction};
  }
`;

export const ExpirationIcon = styled.svg`
  width: 12px;
  height: 12px;

  fill: ${vars.dark30};

  transition: opacity ${vars.transitionTimingFunction};
`;

export const CrossIconWrap = styled.div`
  width: 16px;
  height: 16px;

  position: absolute;
  top: -8px;
  right: -8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${vars.dark70};
  border: 1px solid ${vars.light100};
  border-radius: 50%;
`;

export const CrossIcon = styled.svg`
  width: 12px;
  height: 12px;

  fill: ${vars.light100};
  border-radius: 50%;
`;

export const ButtonSection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  & > section > button {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    & > section > button {
      width: auto;
    }
  }
`;

export const FirstButtonBlock = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 10px;
  }
`;

export const LoaderWrap = styled.section`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10001;
`;
