import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import handleError from 'services/handleError';

export const getUserData = createAsyncThunk(
  'userdata/getUserData',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(`/user/profile/read`);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const changeUserData = createAsyncThunk(
  'userdata/changeUserData',

  async (userData, thunkAPI) => {
    try {
      await axios.post(`/user/profile/update`, userData);

      return userData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const subscriptionCreate = createAsyncThunk(
  'subscription/create',

  async (planType, thunkAPI) => {
    try {
      const { data } = await axios.post(`/subscription/create`, planType);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const subscriptionUpdate = createAsyncThunk(
  'subscription/update',

  async (planType, thunkAPI) => {
    try {
      const response = await axios.post(`/subscription/update`, planType);

      return { status: response.status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const subscriptionRenew = createAsyncThunk(
  'subscription/renew',

  async (_, thunkAPI) => {
    try {
      const response = await axios.post(`/subscription/renew`);

      return { status: response.status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const subscriptionCancel = createAsyncThunk(
  'subscription/cancel',

  async (_, thunkAPI) => {
    try {
      const response = await axios.post(`/subscription/cancel`);

      return { status: response.status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const subscriptionRecover = createAsyncThunk(
  'subscription/recover',

  async (_, thunkAPI) => {
    try {
      const response = await axios.post(`/subscription/recover`);

      return { status: response.status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getBillingPortalUrl = createAsyncThunk(
  'user/billingPortal',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(`/user/billing/portal`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
