import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  BG_REMOVE__BASE_PATH,
  TESTTASK_ID,
  TEST_IMAGE_ID,
  LARGE_IMAGE_PATH,
  SMALL_IMAGE_PATH,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';
import sendFeedback from 'services/sendFeedback';
import getCachedImageBlobUrl from 'services/getCachedImageBlobUrl';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result_transparent.png';

const defaultTask = {
  id: TESTTASK_ID,
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      input_image_small: defaultInputImage,
      input_image_large: defaultInputImage,
      results: [
        {
          id: 'testimage',
          background: [246, 246, 246, 255],
          result_image: defaultInputImage,
          result_image_basic: defaultInputImage,
          result_image_large: defaultInputImage,
        },
        {
          id: 'testimage_transparent',
          background: [0, 0, 0, 0],
          result_image: defaultResultImage,
          result_image_basic: defaultResultImage,
          result_image_large: defaultResultImage,
        },
      ],
    },
  ],
  status: 'test',
};

function getBaseUrl(id) {
  return `${BG_REMOVE__BASE_PATH}/task/${id}/image/`;
}

const processImages = async dataArray => {
  if (!dataArray) return null;

  return await Promise.all(
    dataArray.map(async item => {
      const processedImages = await Promise.all(
        item.images.map(async image => {
          const inputBlob = await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            image.input_image
          ); // Локальний URL для input
          const inputSmallBlob = await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            image.input_image,
            SMALL_IMAGE_PATH
          ); // Локальний URL для input small size
          const inputLargeBlob = await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            image.input_image,
            LARGE_IMAGE_PATH
          ); // Локальний URL для input large size

          const processedResults = await Promise.all(
            image.results.map(async result => ({
              ...result,
              result_image_basic: await getCachedImageBlobUrl(
                getBaseUrl,
                item.id,
                result.result_image
              ), // Локальний URL для result
              result_image_small: await getCachedImageBlobUrl(
                getBaseUrl,
                item.id,
                result.result_image,
                SMALL_IMAGE_PATH
              ), // Локальний URL для result small size
              result_image_large: await getCachedImageBlobUrl(
                getBaseUrl,
                item.id,
                result.result_image,
                LARGE_IMAGE_PATH
              ), // Локальний URL для result large size
            }))
          );
          return {
            ...image,
            input_image: inputBlob,
            input_image_small: inputSmallBlob,
            input_image_large: inputLargeBlob,
            results: processedResults,
          };
        })
      );

      return {
        ...item,
        images: processedImages,
      };
    })
  );
};

export const getBgTaskList = createAsyncThunk(
  'bgremove/gallery',

  async (_, thunkAPI) => {
    try {
      let processedData = [];
      const data = await getTaskList(BG_REMOVE__BASE_PATH, thunkAPI);

      if (data.length > 0) {
        processedData = await processImages(data);
      } else {
        processedData = [defaultTask];
      }

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const goToTheBgTask = createAsyncThunk(
  'bgremove/goToTheBgTask',

  async (taskId, thunkAPI) => {
    try {
      let processedData = [];

      if (taskId === TESTTASK_ID) {
        processedData = [defaultTask];
      } else {
        const data = await goToTheTask(BG_REMOVE__BASE_PATH, taskId, thunkAPI);

        processedData = await processImages([data]); // Обробка всіх зображень
      }

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeBgTask = createAsyncThunk(
  'bgremove/removeTask',

  async (taskId, thunkAPI) => {
    try {
      const data = deleteTask(BG_REMOVE__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

//------------------

export const createBGTaskFromGallery = createAsyncThunk(
  'bgremove/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${BG_REMOVE__BASE_PATH}/task/create-from-gallery`,
        taskData
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createBGTaskFromUploaded = createAsyncThunk(
  'bgremove/createFromUploaded',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      taskData.forEach((item, index) => {
        formData.append('input_files', taskData[index]);
      });
      const { data } = await axios.post(
        `${BG_REMOVE__BASE_PATH}/task/create-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const sendBgImageFeedback = createAsyncThunk(
  'bgremove/imageFeedback',

  async (feedbackBody, thunkAPI) => {
    try {
      const { value, taskId, imageId } = feedbackBody;
      const toolPath = `${BG_REMOVE__BASE_PATH}/task`;

      const responce = await sendFeedback(
        value,
        toolPath,
        taskId,
        imageId,
        thunkAPI
      );

      return responce;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
