import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IMG_UPSCALING__BASE_PATH,
  TESTTASK_ID,
  TEST_IMAGE_ID,
  LARGE_IMAGE_PATH,
  SMALL_IMAGE_PATH,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';
import sendFeedback from 'services/sendFeedback';
import getCachedImageBlobUrl from 'services/getCachedImageBlobUrl';

import defaultInputImage from 'media/images/test_input.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      input_image_small: defaultInputImage,
      input_image_large: defaultInputImage,
      result_image: defaultInputImage,
      result_image_basic: defaultInputImage,
      result_image_large: defaultInputImage,
    },
  ],
  status: 'test',
};

function getBaseUrl(id) {
  return `${IMG_UPSCALING__BASE_PATH}/task/${id}/image/`;
}

const processImages = async dataArray => {
  return await Promise.all(
    dataArray.map(async item => {
      const imagesWithBlobs = await Promise.all(
        item.images.map(async img => ({
          ...img,
          input_image: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image,
            ''
          ),
          input_image_large: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image,
            LARGE_IMAGE_PATH
          ),
          input_image_small: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image,
            SMALL_IMAGE_PATH
          ),
          result_image_basic: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.result_image,
            ''
          ),
          result_image_large: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.result_image,
            LARGE_IMAGE_PATH
          ),
        }))
      );

      return {
        ...item,
        images: imagesWithBlobs,
      };
    })
  );
};

export const getImgUpscalingTaskList = createAsyncThunk(
  'imageUpscaling/gallery',

  async (_, thunkAPI) => {
    try {
      let processedData = [];
      const data = await getTaskList(IMG_UPSCALING__BASE_PATH, thunkAPI);

      if (data.length > 0) {
        processedData = await processImages(data);
      } else {
        processedData = [defaultTask];
      }

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const goToTheImgUpscalingTask = createAsyncThunk(
  'imageUpscaling/goToTheImgUpscalingTask',

  async (taskId, thunkAPI) => {
    let processedData = [];
    try {
      if (taskId === TESTTASK_ID) {
        processedData = [defaultTask];
      } else {
        const data = await goToTheTask(
          IMG_UPSCALING__BASE_PATH,
          taskId,
          thunkAPI
        );

        processedData = await processImages([data]); // Обробка всіх зображень
      }

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeImgUpscalingTask = createAsyncThunk(
  'imageUpscaling/removeTask',

  async (taskId, thunkAPI) => {
    try {
      const data = deleteTask(IMG_UPSCALING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

//------------------

export const createImgUpscalingTaskFromGallery = createAsyncThunk(
  'imageUpscaling/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${IMG_UPSCALING__BASE_PATH}/task/create-from-gallery`,
        taskData
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createImgUpscalingTaskFromUploaded = createAsyncThunk(
  'imageUpscaling/createFromUploaded',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      taskData.forEach((item, index) => {
        formData.append('input_files', taskData[index]);
      });
      const { data } = await axios.post(
        `${IMG_UPSCALING__BASE_PATH}/task/create-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const sendImgUpscalingImageFeedback = createAsyncThunk(
  'imageUpscaling/imageFeedback',

  async (feedbackBody, thunkAPI) => {
    try {
      const { value, taskId, imageId } = feedbackBody;
      const toolPath = `${IMG_UPSCALING__BASE_PATH}/task`;

      const responce = await sendFeedback(
        value,
        toolPath,
        taskId,
        imageId,
        thunkAPI
      );

      return responce;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
