import CreateClothesList from 'components/common/create/CreateClothesList';

import { MODAL_META } from 'utils/constants';

import {
  ModalButtonAccent,
  ModalButtonLight,
  LightButton,
} from 'components/common/Buttons/Buttons';

import {
  Content,
  ModalTitle,
  ModalText,
  ContentWithImage,
  ModalButtonWrap,
  ColumnOfButtons,
} from 'components/common/modals/ModalMessageTemplates/ModalMessageTemplates.styled';

export const ForInformation = ({ title, message, buttonText, closeModal }) => {
  return (
    <Content>
      <div>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
      </div>
      <ModalButtonLight closeModal={closeModal}>{buttonText}</ModalButtonLight>
    </Content>
  );
};

export const WarningMessage = ({ title, message, buttonText, closeModal }) => {
  return (
    <Content>
      <div>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
      </div>
      <ModalButtonAccent closeModal={closeModal}>
        {buttonText}
      </ModalButtonAccent>
    </Content>
  );
};

export const WarningMessageWithImage = ({
  title,
  message,
  images,
  handleDeleteImage,
  isErrors,
  closeModal,
  onCloseModalWithAddingImages,
}) => {
  return (
    <ContentWithImage>
      <div>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
      </div>

      <CreateClothesList
        fileList={images}
        handleDeleteImage={handleDeleteImage}
      />
      <ModalButtonWrap>
        <ModalButtonLight closeModal={closeModal}>
          Cancel adding
        </ModalButtonLight>
        <ModalButtonAccent
          disableStatus={
            isErrors || (!isErrors && images && images.length === 0)
          }
          closeModal={onCloseModalWithAddingImages}
        >
          Add images
        </ModalButtonAccent>
      </ModalButtonWrap>
    </ContentWithImage>
  );
};

export const ChooseOneOption = ({
  title,
  message,
  closeModalWithFirstOption,
  closeModalWithSecondOption,
}) => {
  return (
    <Content>
      <div>
        <ModalTitle>{MODAL_META.attention.title}</ModalTitle>
        <ModalText>{title}</ModalText>
        <ModalText>{message}</ModalText>
      </div>
      <ModalButtonWrap>
        <ModalButtonLight closeModal={closeModalWithFirstOption}>
          {MODAL_META.attention.buttonText}
        </ModalButtonLight>
        <ModalButtonLight closeModal={closeModalWithSecondOption}>
          {MODAL_META.success.buttonTextForOrder}
        </ModalButtonLight>
      </ModalButtonWrap>
    </Content>
  );
};

export const ChooseOneOptionWithoutWarningTitle = ({
  title,
  firstButtonText,
  secondButtonText,
  closeModalWithFirstOption,
  closeModalWithSecondOption,
}) => {
  return (
    <Content>
      <ModalTitle>{title}</ModalTitle>

      <ColumnOfButtons>
        <ModalButtonAccent closeModal={closeModalWithFirstOption}>
          {firstButtonText}
        </ModalButtonAccent>
        <LightButton handleClick={closeModalWithSecondOption}>
          {secondButtonText}
        </LightButton>
      </ColumnOfButtons>
    </Content>
  );
};
