import { BASE_URL, API__PREFIX, API__VERSION } from 'utils/configs';

async function getImageBlobUrl(toolPath, imageUrl, imageSize = '') {
  if (!imageUrl) return null;

  try {
    // Construct and return the URL directly instead of making an axios request
    const imageUrl_Processed = `${BASE_URL}${API__PREFIX}${API__VERSION}${toolPath}${imageUrl}${imageSize}`;
    return imageUrl_Processed;
  } catch (error) {
    console.error('Image URL construction error');
    return null;
  }
}

export default getImageBlobUrl;
