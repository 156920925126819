import axios from 'axios';
import handleError from 'services/handleError';

const deleteTask = async (toolPath, taskId, thunkAPI) => {
  let taskType = '';
  if (toolPath !== `/ai-fashion-store/order`) {
    taskType = '/task';
  }

  try {
    await axios.post(`${toolPath}${taskType}/${taskId}/delete`);

    return taskId;
  } catch (error) {
    const { status, data } = error.response;
    const errorDetails = handleError(status, data);

    return thunkAPI.rejectWithValue(errorDetails);
  }
};

export default deleteTask;
