function getEnv(key, defaultVal) {
  let envValue = process.env[key];

  if (envValue === undefined) {
    return defaultVal;
  } else {
    return envValue;
  }
}

/**---------common--------*/
export const BASE_URL = getEnv(
  'REACT_APP__API__BASE_URL',
  'http://localhost:8000'
);

export const COOKIE_DOMAIN_NAME = getEnv(
  'REACT_APP__API__COOKIE_DOMAIN_NAME',
  'localhost'
);

/**------------------------- */

export const API__PREFIX = getEnv('REACT_APP__API__PREFIX', '');

export const API__VERSION = '/v1';

export const API__IMAGES_PATH = getEnv(
  'REACT_APP__API__IMAGES_PATH',
  '/image/'
);

export const AVATAR_PATH = getEnv(
  'REACT_APP__AI_PHOTOSHOOTING__API__AVATAR_PATH',
  '/avatar/image/'
);

/**---------ai-photoshooting--------*/

export const AI_PHOTOSHOOTING__BASE_PATH = getEnv(
  'REACT_APP__API__AI_PHOTOSHOOTING__BASE_PATH',
  '/ai-photoshooting'
);

/**---------ai-generate-models--------*/

export const AI_GENERATE_MODELS__BASE_PATH = getEnv(
  'REACT_APP__API__AI_GENERATE_MODELS__BASE_PATH',
  '/ai-generate-models'
);

/**---------bg-remove--------*/

export const BG_REMOVE__BASE_PATH = getEnv(
  'REACT_APP__API__BG_REMOVE__BASE_PATH',
  '/bg-remove'
);

/**---------image-upscaling--------*/

export const IMG_UPSCALING__BASE_PATH = getEnv(
  'REACT_APP__API__IMG_UPSCALING__BASE_PATH',
  '/image-upscaling'
);

/**---------ai-fashion-store--------*/

export const AI_FASHION_STORE__BASE_PATH = getEnv(
  'REACT_APP__API__AI_FASHION_STORE__BASE_PATH',
  '/ai-fashion-store/order'
);

export const AI_FASHION_STORE__ROOT_PATH = '/ai-fashion-store';

/*--------------test-tasks----------------*/

export const TESTTASK_ID = 'testtask';
export const TEST_IMAGE_ID = 'testimages';

/*---------------demo----------------*/

export const DEMO__BASE_PATH = getEnv(
  'REACT_APP__API__DEMO__BASE_PATH',
  '/demo'
);

/*--------------images----------------*/

export const LARGE_IMAGE_PATH = '?size=400x450';
export const SMALL_IMAGE_PATH = '?size=70x70';

/**---------intercom--------*/

export const INTERCOM_APP_ID = getEnv('REACT_APP__INTERCOM_APP_ID', '');

/**-----------amplify---------- */

export const USER_POOL_ID = getEnv(
  'REACT_APP__USER_POOL__POOL_ID',
  'eu-central-1_01mLkMAym'
);
export const USER_POOL_CLIENT_ID = getEnv(
  'REACT_APP__USER_POOL__CLIENT_ID',
  '4unkvap9cr4apcb9qmc1d0irle'
);
export const USER_POOL_DOMAIN = getEnv(
  'REACT_APP__USER_POOL__DOMAIN',
  'mod-y.auth.eu-central-1.amazoncognito.com'
);
export const USER_POOL_SIGN_IN_URL = getEnv(
  'REACT_APP__USER_POOL__SIGN_IN_URL',
  'https://app.mod-y.com'
);
export const USER_POOL_SIGN_OUT_URL = getEnv(
  'REACT_APP__USER_POOL__SIGN_OUT_URL',
  'https://app.mod-y.com'
);

/** Other links */

export const SHOPIFY_API_KEY = getEnv(
  'REACT_APP__SHOPIFY_API_KEY',
  '6d2058f85977d44483897d224089b942'
);
export const SHOPIFY_USER_STORE_BASE_LINK = 'https://admin.shopify.com/store';

export const SHOPIFY_MODY_APP_URL = 'https://apps.shopify.com/';

/** Other links */

export const MEETING_LINK =
  'https://calendly.com/artempichka/mod-y-meetings-canada-usa-clone';

export const PLAN_TYPE__BASIC = 'basic';
export const PLAN_TYPE__PRO = 'pro';
export const PLAN_TYPE__BUSINESS = 'business';
export const PLAN_TYPE__ENTERPRISE = 'enterprise';

export const LANDING_LINK = 'https://www.mod-y.com/';

export const TERMS_LINK =
  'https://app.termly.io/policy-viewer/policy.html?policyUUID=f013560c-bd3c-4558-860f-d5f6aa2cee16';

export const POLICY_LINK =
  'https://app.termly.io/policy-viewer/policy.html?policyUUID=514b2eee-9523-4875-ae94-718b7a8d3da4';

export const COMPANY_INFO_LINK = 'https://www.mod-y.com/blank';

export const API_DOCS_LINK = 'https://api.app.mod-y.com/docs';

export const LINKEDIN_LINK = 'https://www.linkedin.com/company/mod-y/';
