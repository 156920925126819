import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AI_PHOTOSHOOTING__BASE_PATH,
  AVATAR_PATH,
  LARGE_IMAGE_PATH,
  SMALL_IMAGE_PATH,
  TESTTASK_ID,
  TEST_IMAGE_ID,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';
import sendFeedback from 'services/sendFeedback';
import processAvatarImages from 'services/processAvatarImages';
import getCachedImageBlobUrl from 'services/getCachedImageBlobUrl';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  avatar: { image: '07966_00.jpeg' },
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      input_image_small: defaultInputImage,
      input_image_large: defaultInputImage,
      result_image: defaultResultImage,
      result_image_basic: defaultResultImage,
      result_image_large: defaultResultImage,
    },
  ],
  status: 'test',
};

function getBaseUrl(id) {
  return `${AI_PHOTOSHOOTING__BASE_PATH}/task/${id}/image/`;
}

function getBaseAvatarUrl(id) {
  return `${AI_PHOTOSHOOTING__BASE_PATH}${AVATAR_PATH}`;
}

const processImages = async dataArray => {
  return await Promise.all(
    dataArray.map(async item => {
      const avatarBlob = await getCachedImageBlobUrl(
        getBaseAvatarUrl,
        'null',
        item.avatar.image
      );

      const imagesWithBlobs = await Promise.all(
        item.images.map(async img => ({
          ...img,
          input_image: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image
          ),
          input_image_large: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image,
            LARGE_IMAGE_PATH
          ),
          input_image_small: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.input_image,
            SMALL_IMAGE_PATH
          ),
          result_image_basic: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.result_image,
            ''
          ),
          result_image_large: await getCachedImageBlobUrl(
            getBaseUrl,
            item.id,
            img.result_image,
            LARGE_IMAGE_PATH
          ),
        }))
      );

      return {
        ...item,
        avatar: { ...item.avatar, image: avatarBlob },
        images: imagesWithBlobs,
      };
    })
  );
};

export const getAvatars = createAsyncThunk(
  'aiphotoshooting/getAvatars',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${AI_PHOTOSHOOTING__BASE_PATH}/avatar/query
        `
      );

      const processedData = await processAvatarImages(data); // Обробка всіх зображень

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createTask = createAsyncThunk(
  'aiphotoshooting/create',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      Object.entries(taskData).forEach(([key, value]) => {
        if (key === 'avatar_id') {
          formData.append(key, value);
        } else {
          value.forEach((item, index) => {
            formData.append('input_files', value[index]);
          });
        }
      });

      const { data } = await axios.post(
        `${AI_PHOTOSHOOTING__BASE_PATH}/task/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createAiPhotoshootingTaskFromGallery = createAsyncThunk(
  'aiphotoshooting/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${AI_PHOTOSHOOTING__BASE_PATH}/task/create-from-gallery`,
        taskData
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const getAiPhotoshootingTaskList = createAsyncThunk(
  'aiphotoshooting/gallery',

  async (_, thunkAPI) => {
    try {
      let processedData = [];
      const data = await getTaskList(AI_PHOTOSHOOTING__BASE_PATH, thunkAPI);

      if (data.length > 0) {
        processedData = await processImages(data); // Обробка всіх зображень
      } else {
        const avatarBlob = await getCachedImageBlobUrl(
          getBaseAvatarUrl,
          'null',
          defaultTask.avatar.image
        );
        processedData = [{ ...defaultTask, avatar: { image: avatarBlob } }];
      }

      return processedData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const goToTheAiPhotoshootingTask = createAsyncThunk(
  'aiphotoshooting/goToTheTask',

  async (taskId, thunkAPI) => {
    let processedData = [];
    try {
      if (taskId === TESTTASK_ID) {
        const avatarBlob = await getCachedImageBlobUrl(
          getBaseAvatarUrl,
          'null',
          defaultTask.avatar.image
        ); // Обробка всіх зображень

        processedData = [{ ...defaultTask, avatar: { image: avatarBlob } }];
      } else {
        const data = await goToTheTask(
          AI_PHOTOSHOOTING__BASE_PATH,
          taskId,
          thunkAPI
        );

        processedData = await processImages([data]); // Обробка всіх зображень
      }

      return processedData[0];
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeTask = createAsyncThunk(
  'aiphotoshooting/removeTask',

  async (taskId, thunkAPI) => {
    try {
      const data = deleteTask(AI_PHOTOSHOOTING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const sendAiPhotoshootingImageFeedback = createAsyncThunk(
  'aiphotoshooting/imageFeedback',

  async (feedbackBody, thunkAPI) => {
    try {
      const { value, taskId, imageId } = feedbackBody;
      const toolPath = `${AI_PHOTOSHOOTING__BASE_PATH}/task`;

      const responce = await sendFeedback(
        value,
        toolPath,
        taskId,
        imageId,
        thunkAPI
      );

      return responce;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
