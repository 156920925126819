import getImageBlobUrl from 'services/getImageBlobUrl';

// Кеш для збереження вже оброблених URL
const blobUrlCache = new Map();

const getCachedImageBlobUrl = async (
  getBaseUrl,
  id,
  imageUrl,
  imageSize = ''
) => {
  const cachedImage = `${imageUrl}${imageSize}`;
  if (blobUrlCache.has(cachedImage)) {
    return blobUrlCache.get(cachedImage); // Повертаємо кешований URL
  }

  const blobUrl = await getImageBlobUrl(getBaseUrl(id), imageUrl, imageSize); // Отримуємо новий blob URL
  blobUrlCache.set(cachedImage, blobUrl); // Зберігаємо у кеш

  return blobUrl;
};

export default getCachedImageBlobUrl;
