import axios from 'axios';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { BASE_URL, API__PREFIX, API__VERSION } from 'utils/configs';

function refreshAxiosInstance(accessToken) {
  axios.defaults.baseURL = `${BASE_URL}${API__PREFIX}${API__VERSION}`;
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  axios.defaults.withCredentials = true;
}

async function getShopifySessionToken(app) {
  try {
    const sessionToken = await getSessionToken(app);
    return { 'X-Shopify-Authorization': `Bearer ${sessionToken}` };
  } catch (error) {
    console.error('Error fetching Shopify session token:', error);
    return null;
  }
}

export { refreshAxiosInstance, getShopifySessionToken };
